<template>
  <div class="csn-login"></div>
</template>

<script>
import { LOGIN } from '@/constants'

export default {
  name: LOGIN,
};
</script>
